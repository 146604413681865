<template>
<div id="app">
  <Layout :class="viewClass">
    <Card shadow>
      <Layout>
        <Header :style="{background:'#fff', padding: '25px'}">
          <Steps :current="currentStep" :direction="(window.width < window.mobileThreshold) ? 'vertical' : 'horizontal'">
            <Step title="Shopping carts" @click.native="gotoStep(0)" :style="{cursor: 'pointer'}"></Step>
            <Step title="Return Rate" @click.native="gotoStep(1)" :style="{cursor: 'pointer'}"></Step>
						<Step title="Return Costs" @click.native="gotoStep(2)" :style="{cursor: 'pointer'}"></Step>
						<Step title="Shopping cart value" @click.native="gotoStep(3)" :style="{cursor: 'pointer'}"></Step>
          </Steps>
        </Header>
        <Content :style="{padding: '25px', background: '#fff', 'min-height': '300px'}">

          <Content :style="{'margin-top': '50px'}">
            <transition-group :name="stepAnimation">

              <div class="step" v-if="currentStep === 0" key="0">
                <h2>Average shopping carts per month?</h2>
                <div class="form">
                  <Slider v-model="parameter.orderAmount" :min="50" :max="20000" show-tip="never"></Slider>
                  <InputNumber :min="50" :max="20000" v-model="parameter.orderAmount" size="large" :active-change="false" @change.native="updateValue($event, 'orderAmount')"/> shopping carts
                </div>
              </div>

              <div class="step" v-if="currentStep === 1" key="1">
                <h2>What percentage of returns are due to wrong sizes?</h2>
                <div class="form">
                  <Slider v-model="parameter.returnPercentage" show-tip="never"></Slider>
                  <InputNumber :min="0" :max="100" v-model="parameter.returnPercentage" size="large" :active-change="false" @change.native="updateValue($event, 'returnPercentage')"/> %
                </div>
              </div>

              <div class="step" v-if="currentStep === 2" key="2">
                <h2>What is the cost of a single return?</h2>
                <div class="form">
                  <Slider v-model="parameter.returnPrice" show-tip="never"></Slider>
                  <InputNumber :min="0" :max="100" v-model="parameter.returnPrice" size="large" :active-change="false" @change.native="updateValue($event, 'returnPrice')"/> €
                </div>
              </div>

							<div class="step" v-if="currentStep === 3" key="3">
								<h2>What is the average value of a shopping cart?</h2>
								<div class="form">
									<Slider v-model="parameter.avgCartValue" :min="0" :max="1000" show-tip="never"></Slider>
									<InputNumber :min="0" :max="1000" v-model="parameter.avgCartValue" size="large" :active-change="false" @change.native="updateValue($event, 'avgCartValue')"/> €
								</div>
							</div>

            </transition-group>
          </Content>

        </Content>
        <Footer :style="{background:'#fff', padding: '25px', 'text-align': 'center'}">
          <ButtonGroup shape="circle">
            <Button type="primary" @click="previous()">
              <Icon type="ios-arrow-back"></Icon>
              Back
            </Button>
            <Button type="primary" @click="next()">
              Next
              <Icon type="ios-arrow-forward"></Icon>
            </Button>
          </ButtonGroup>
        </Footer>
      </Layout>

    </Card>
		<span :style="{ color: 'lightgray', position: 'absolute', bottom: 0 }">{{ version }}</span>
  </Layout>

	<Modal
		v-model="roiModal"
		width="550"
		:closable="false"
		:mask-closable="false">
		<p slot="header" :style="{color:'#515a6e', 'text-align':'center'}">
			<span v-if="window.width < window.mobileThreshold">Save money with TailorGuide!</span>
			<span v-else>This is how much you could save with TailorGuide</span>
		</p>
		<div :style="{'text-align':'center'}">
			<transition-group name="megaRoi">
				<h3 v-if="packageName === 'Enterprise'" :style="{'font-size':'25px', 'font-weight': 900, color: '#00be68', 'line-height': '100px'}" :key="0">Please ask</h3>
				<div v-if="packageName !== 'Enterprise'" :key="1">
					<Row :gutter="window.width < window.tagThreshold ? 30 : 40" :style="{'margin-top': '20px','margin-bottom': window.width < window.tagThreshold ? '30px' : '40px'}">
						<Col span="12">
							<div class="roi-box" :class="{ zero: tweening }" :style="{color: '#00be68'}">
								<h3 class="roi-box-title">{{ animatedRoiAmount }}</h3>
								<span class="roi-box-description">savings per month</span>
							</div>
						</Col>
						<Col span="12">
							<div class="roi-box" :class="{ zero: tweening }" :style="{color: '#00be68'}">
								<h3 class="roi-box-title">{{ animatedRoiPerYearAmount }}</h3>
								<span class="roi-box-description">savings per year</span>
							</div>
						</Col>
					</Row>
					<transition name="megaRoi">
					<Row v-if="packagePrice !== 'FREE'" :style="{'margin-bottom': window.width < window.tagThreshold ? '30px' : '40px'}">
						<Col>
							<div class="roi-box" :class="{ zero: tweening }" :style="{color: '#00be68'}">
								<h3 class="roi-box-title">{{ animatedRoiPercentage }} %</h3>
								<span class="roi-box-description">ROI percentage</span>
							</div>
						</Col>
					</Row>
					</transition>
				</div>
			</transition-group>

			<div :style="{'text-align': 'left', margin: '30px 0 15px 0'}">
				<Collapse simple>
					<Panel name="1">
						<span v-if="window.width < window.mobileThreshold">How we calculate your Return On Investment</span>
						<span v-else>See and adjust how we calculate your Return On Investment</span>
						<p slot="content">
							<strong>Shopping carts per month:</strong><br/>
							{{ parameter.orderAmount }}
							<Slider v-model="parameter.orderAmount" :min="50" :max="20000" show-tip="never" @on-change="updateRoi"></Slider>
							<strong>Return rate:</strong><br/>
							{{ parameter.returnPercentage }} % = <em>return total: {{ returnAmount.toFixed(1) }}</em>
							<Slider v-model="parameter.returnPercentage" show-tip="never" @on-change="updateRoi"></Slider><br/>
							<strong>Cost of single return:</strong><br/>
							{{ toCurrency(parameter.returnPrice) }} = <em>total return cost <span :style="{color: 'red'}">{{ toCurrency(returnCostTotal) }}</span> and after TailorGuide <span :style="{color: 'green'}">{{ toCurrency(returnCostTotalWithTG) }}</span></em>
							<Slider v-model="parameter.returnPrice" show-tip="never" @on-change="updateRoi"></Slider>
							<strong>Average shopping cart value:</strong><br/>
							{{ toCurrency(parameter.avgCartValue) }}
							<Slider v-model="parameter.avgCartValue" :min="0" :max="5000" show-tip="never" @on-change="updateRoi"></Slider>
							<Collapse simple>
								<Panel name="2">
									More
									<p slot="content">
										<strong>Percentage of shoppers using TailorGuide:</strong><br/>
										{{ parameter.cartsWithTGPercentage }} % = <em>{{ cartsWithTG.toFixed(1) }} shoppers</em>, total <span :style="{color: 'red'}">{{ toCurrency(uniqueUsersCost) }}</span>
										<Slider v-model="parameter.cartsWithTGPercentage" show-tip="never" @on-change="updateRoi"></Slider><br/>
										<strong>Number of size charts:</strong><br/>
										{{ parameter.sizeCharts }} = total: <span :style="{color: 'red'}">{{ toCurrency(sizeChartsCost) }}</span>
										<Slider v-model="parameter.sizeCharts" :min="1" :max="2000" show-tip="never" @on-change="updateRoi"></Slider><br/>
										<strong>When TailorGuide is used, size related returns will be reduced by:</strong><br/>
										{{ parameter.tgHitRatePercentage }} % = <em>total returns with TailorGuide: {{ returnAmountAfterTG.toFixed(1) }}</em>
										<Slider v-model="parameter.tgHitRatePercentage" show-tip="never" @on-change="updateRoi"></Slider><br/>
										<strong>My profit margin per shopping cart:</strong><br/>
										{{ parameter.profitMarginPercentage }} % = <em>profit per shopping cart: {{ toCurrency(profitPerCart) }}</em>
										<Slider v-model="parameter.profitMarginPercentage" show-tip="never" @on-change="updateRoi"></Slider><br/>
										<strong>Percentage increase in total number of shopping carts due to TailorGuide:</strong><br/>
										{{ parameter.additionalCartsAfterTGPercentage }} % = <em>{{ additionalCartsAfterTG.toFixed(1) }} shopping carts which equals <span :style="{color: 'green'}">{{ toCurrency(additionalCartsValueAfterTG) }}</span> profit</em>
										<Slider v-model="parameter.additionalCartsAfterTGPercentage" show-tip="never" @on-change="updateRoi"></Slider><br/>
									</p>
								</Panel>
							</Collapse>
						</p>
					</Panel>
				</Collapse>
			</div>

			<CellGroup :style="{'text-align': 'left'}">
				<Cell :title="(window.width < window.mobileThreshold) ? 'Which plan is right for me?' : 'Which TailorGuide plan is right for my store?'" extra="Monthly fee"/>
				<span v-for="(level, index) in packages" :key="index">
					<Cell :selected="selectedIndex === index" :disabled="level.name === 'Basic' && cartsWithTG > users[0].to" @click.native="select(index)">
						{{ level.name }}
						<span v-if="level.name === 'Basic'" slot="label">
							Up to 100 unique users
						</span>
						<span v-if="level.price === 'FREE'" slot="extra">
							<Tag v-if="window.width >= window.tagThreshold" :class="{ hide: bestValueIndex !== index }" color="success" :style="{'margin-right': '30px','font-weight': 400,'pointer-events': 'none'}">Best Value</Tag>
							{{ level.price }}
							<span v-if="window.width < window.tagThreshold"><br/><Tag :class="{ hide: bestValueIndex !== index }" color="success" :style="{'margin-right': '-5px','font-weight': 400,'pointer-events': 'none'}">Best Value</Tag></span>
						</span>
						<span v-else slot="extra">
							<Tag v-if="window.width >= window.tagThreshold" :class="{ hide: bestValueIndex !== index }" color="success" :style="{'margin-right': '30px','font-weight': 400,'pointer-events': 'none'}">Best Value</Tag>
							{{ toCurrency(level.price) }}
							<span v-if="window.width < window.tagThreshold"><br/><Tag :class="{ hide: bestValueIndex !== index }" color="success" :style="{'margin-right': '-5px','font-weight': 400,'pointer-events': 'none'}">Best Value</Tag></span>
						</span>
					</Cell>
				</span>
			</CellGroup>
		</div>
		<div slot="footer">
			<Button type="success" size="large" long @click.native="done">Let's do it!</Button>
		</div>
	</Modal>

</div>
</template>

<script>
//import HelloWorld from './components/HelloWorld.vue'
import uuidv4 from 'uuid/v4';
import pkg from '../package';

export default {
  name: 'app',
  data: function() {
    return {
			contactPage: "https://tailor.guide/en/ecommerce#contact",
			version: pkg.version,
			window: {
				mobileThreshold: 900,
				tagThreshold: 480,
				height: 0,
				width: 0
			},
			eventApiUrl: "https://event-api.tailor.guide",
			eventTimer: 0,
			currentStep: 0,
			maxSteps: 4,
			stepAnimation: 'fade',
			parameter: {
				orderAmount: 1000,
				returnPercentage: 15,
				returnPrice: 9,
				avgCartValue: 65,
				cartsWithTGPercentage: 50,
				sizeCharts: 50,
				tgHitRatePercentage: 90,
				profitMarginPercentage: 30,
				additionalCartsAfterTGPercentage: 10
			},
			packages: [
				{
					name: "Basic",
					price: "FREE"
				},
				{
					name: "Pro",
					price: 99
				},
				{
					name: "Enterprise",
					price: 990
				}
			],
			users: [
				{ from: 0, to: 100, price: 0 },
				{ from: 101, to: 5000, price: 0.29 },
				{ from: 5001, to: 50000, price: 0.21 },
				{ from: 50001, price: 0.13 }
			],
			charts: [
				{ from: 0, to: 100, price: 0 },
				{ from: 101, to: 500, price: 0.9 },
				{ from: 501, to: 1000, price: 0.65 },
				{ from: 1001, price: 0.5 }
			],
			selectedIndex: 1,
			bestValueIndex: 1,
			roiModal: false,
			tweening: false,
			tweenedRoi: 0,
			tweenedPercentage: 0,
			roi: 0,
			percentage: 0
		}
	},
	components: {
    //    HelloWorld
	},
	computed: {
		cartsWithTG: function() {
			return this.parameter.orderAmount * (this.parameter.cartsWithTGPercentage / 100);
		},
		uniqueUsersCost: function() {
			let cost = 0;
			for (let i = 0; i < this.users.length; i++) {
				if (i > 0 && this.cartsWithTG > this.users[0].to) {
					if (typeof this.users[i].to !== "undefined" && this.cartsWithTG > this.users[i].to) {
						cost += (this.users[i].to - (this.users[i].from - 1)) * this.users[i].price;
					} else {
						cost += (this.cartsWithTG - (this.users[i].from - 1)) * this.users[i].price;
						break;
					}
				}
			}

			return cost;
		},
		sizeChartsCost: function() {
			let cost = 0;
			for (let i = 0; i < this.charts.length; i++) {
				if (i > 0 && this.parameter.sizeCharts > this.charts[0].to) {
					if (typeof this.charts[i].to !== "undefined" && this.parameter.sizeCharts > this.charts[i].to) {
						cost += (this.charts[i].to - (this.charts[i].from - 1)) * this.charts[i].price;
					} else {
						cost += (this.parameter.sizeCharts - (this.charts[i].from - 1)) * this.charts[i].price;
						break;
					}
				}
			}

			return cost;
		},
		returnAmount: function() {
			return this.parameter.orderAmount * (this.parameter.returnPercentage / 100);
		},
		returnCostTotal: function() {
			return this.returnAmount * this.parameter.returnPrice;
		},
		returnAmountCutWithTG: function() {
			return this.returnAmount * (this.parameter.cartsWithTGPercentage / 100);
		},
		returnAmountAfterTG: function() {
			return this.returnAmount - (this.returnAmountCutWithTG * (this.parameter.tgHitRatePercentage / 100));
		},
		returnCostTotalWithTG: function() {
			return this.returnAmountAfterTG * this.parameter.returnPrice;
		},
		returnSavingWithTG: function() {
			return this.returnCostTotal - this.returnCostTotalWithTG;
		},
		profitPerCart: function() {
			return this.parameter.avgCartValue * (this.parameter.profitMarginPercentage / 100);
		},
		additionalCartsAfterTG: function() {
			return this.parameter.orderAmount * (this.parameter.additionalCartsAfterTGPercentage / 100);
		},
		additionalCartsValueAfterTG: function() {
			return this.profitPerCart * this.additionalCartsAfterTG;
		},
		roiAmount: function() {
			const price = (this.packagePrice === "FREE") ? 0 : this.packagePrice;
			return this.additionalCartsValueAfterTG + this.returnSavingWithTG - this.uniqueUsersCost - this.sizeChartsCost - price;
		},
		roiPercentage: function() {
			return (this.roiAmount/this.packagePrice) * 100;
		},
		animatedRoiAmount: function() {
			return this.toCurrency((this.tweenedRoi < 0) ? 0 : this.tweenedRoi);
		},
		animatedRoiPerYearAmount: function() {
			return this.toCurrency(((this.tweenedRoi < 0) ? 0 : this.tweenedRoi) * 12);
		},
		animatedRoiPercentage: function() {
			return (this.tweenedPercentage < 0) ? 0 : this.tweenedPercentage.toFixed(2);
		},
		packagePrice: function() {
			return this.packages[this.selectedIndex].price;
		},
		packageName: function() {
			return this.packages[this.selectedIndex].name;
		},
		viewClass: function() {
			return (this.window.width < this.window.mobileThreshold) ? "mobile" : "desktop";
		}
	},
	watch: {
		cartsWithTG: function(newValue) {
			if (newValue <= this.users[0].to) {
				this.select(0, true);
			} else {
				this.select(1, true);
			}
		},
		roi: function(newValue) {
			let self = this;
			TweenLite.to(this.$data, 1.5, { tweenedRoi: newValue, onStart: () => { self.tweening = true }, onComplete: () => { self.tweening = false } })
		},
		percentage: function (newValue) {
			TweenLite.to(this.$data, 1, { tweenedPercentage: newValue })
		}
	},
  created: function() {
		if (window.localStorage.getItem("uuid") === null) {
			window.localStorage.setItem("uuid", uuidv4());
		}

		window.addEventListener('resize', this.handleResize);
    this.handleResize();

		let tweenLite = document.createElement('script');
		tweenLite.setAttribute("src", "https://cdnjs.cloudflare.com/ajax/libs/gsap/2.0.2/TweenMax.min.js");
		document.head.appendChild(tweenLite);
	},
	destroyed: function() {
    window.removeEventListener('resize', this.handleResize);
  },
  methods: {
    next() {
      if (this.currentStep >= this.maxSteps - 1) {
				// this.gotoStep(0);
				this.roiModal = true;
				this.updateRoi(true);
      } else {
				this.stepAnimation = "slider";
				this.currentStep++;
      }
    },
    previous() {
      if (this.currentStep <= 0) {
				// this.gotoStep(this.maxSteps - 1);
      } else {
				this.stepAnimation = "slidel";
				this.currentStep--;
      }
    },
    gotoStep(step) {
			if (step > this.currentStep) {
				this.stepAnimation = "slider"
			} else if (step < this.currentStep) {
				this.stepAnimation = "slidel"
			} else {
				this.stepAnimation = "fade";
			}
			this.currentStep = step;
		},
		updateValue(event, valueName) {
			this.parameter[valueName] = Number(event.target.value);
		},
		select(index, preselect = false) {
			if (preselect) {
				this.bestValueIndex = index;
			}
			if (this.cartsWithTG <= this.users[0].to || (this.cartsWithTG > this.users[0].to && index > 0)) {
				this.selectedIndex = index;
				if (this.roiModal) {
					this.updateRoi();
				}
			}
		},
		updateRoi(start = false) {
			this.roi = this.roiAmount;
			this.percentage = this.roiPercentage;

			if (start === true) {
				this.sendEvent("open");
			} else if (!start) {
				if (this.eventTimer) {
					clearTimeout(this.eventTimer);
				}

				this.eventTimer = setTimeout(() => {
					this.sendEvent("update");
				}, 5000);
			}

		},
		toCurrency(value) {
			return new Intl.NumberFormat("lookup", { style: "currency", currency: "EUR" }).format(value);
		},
		done() {
			if (window.opener !== null) {
				window.opener.location.href = this.contactPage;
				window.close();
			} else {
				window.location = this.contactPage;
			}
		},
		sendEvent(action) {
			let data = {
				parameter: this.parameter,
				result: {
					roiAmount: this.roi,
					roiPercentage: this.percentage
				}
			};
			let body = JSON.stringify({
				category: "roi-calculator",
				cid: window.localStorage.getItem("uuid"),
				action,
				data
			})

			// fetch(this.eventApiUrl + "/event/data", {
			// 	method: "POST",
			// 	headers: {
			// 		"Content-Type": "application/json"
			// 	},
			// 	body
			// }).then().catch()
		},
		handleResize() {
			this.window.width = window.innerWidth;
			this.window.height = window.innerHeight;
		}
	}
}
</script>

<style lang="scss">
html {
	min-width: 320px;
}

.ivu-card-body {
    overflow: hidden;
    position: relative;
}

.slider-enter-active,
.slider-leave-active {
    transition: 0.4s;
}

.slider-enter {
    transform: translate(100%, 0);
}

.slider-leave-to {
    width: 100%;
    position: absolute;
    transform: translate(-100%, 0);
    opacity: 0;
}

.slidel-enter-active,
.slidel-leave-active {
    transition: 0.4s;
}

.slidel-enter {
    transform: translate(-100%, 0);
}

.slidel-leave-to {
    width: 100%;
    position: absolute;
    transform: translate(100%, 0);
    opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
    transition: 0.4s;
}

.fade-enter {
    transform: translate(0, -100%);
}

.fade-leave-to {
    width: 100%;
    transform: translate(0, 100%);
    position: absolute;
    opacity: 0;
}

.megaRoi-enter,
.megaRoi-leave-to {
	max-height: 0px;
	opacity: 0;
}

.megaRoi-enter-to,
.megaRoi-leave {
	max-height: 400px;
	opacity: 1;
}

.megaRoi-enter-active,
.megaRoi-leave-active {
	overflow: hidden;
	transition: all 200ms ease-in-out;
}

.megaRoi-enter-active {
	transition-delay: 200ms;
}

.ivu-cell-disabled {
	opacity: 0.3;
}

.ivu-cell-selected {
	color:white !important;
	background-color: #53a1f6 !important;
	font-weight: 900;
}

.ivu-cell-group {
	margin: 0 -16px !important;
}

.ivu-cell-label {
	margin-bottom: 4px !important;
}

.ivu-cell-footer {
	right: 32px !important;
	text-align: right;
}

.ivu-cell {
	padding: 7px 32px !important;
}

.ivu-card-body {
	min-height: 530px !important;
}

.hide {
	visibility: hidden;
}

.roi-box {

	.roi-box-title {
		font-size: 25px;
		font-weight: 900
	}

	.roi-box-description {
		font-size: 16px;
		margin-top: -7px;
		color: #74d0a6;
		display: inline-block;
		font-weight: 500;
	}

	display: block;
	border-radius: 10px;
	overflow: hidden;
	border: 1px solid #e0ece6;
	box-shadow: 7px 7px 20px 0px #0a522d1a;
	position: relative;
	text-align: left;
	padding: 10px 20px 45px 20px;
	width: 100%;
}

.roi-box::before {
	content: "";
	display: block;
	background: linear-gradient(to right, #e4f7ea00 20%,#92e8ac 100%);
	transform: rotate(-14deg);
	left: 0;
	transition: all 1s ease-in-out;
	opacity: 0.4;
	right: -60%;
	bottom: -150%;
	transform-origin: bottom left;
	position: absolute;
	top: 100%;
}
.roi-box.zero::before {
	transform: rotate(0);
	transition-duration: 0s;
}
.desktop {

	.ivu-steps-item {
		width: 25% !important;
	}

	.ivu-layout-content {
		.ivu-layout-content {
			margin-top: 50px !important;
		}
	}

	.step {
		.form {
			padding: 50px;
			max-width: 80%;
			margin: auto;
		}

		text-align: center;
	}

	padding: 50px;
	min-height: 100vh;
}

.mobile {

	.ivu-steps-item {
		width: 100% !important;
	}

	.ivu-layout-content {
		.ivu-layout-content {
			margin-top: 0px !important;
		}
	}

	.step {
		.form {

			.ivu-slider {
				margin-bottom: 50px;
			}

			padding: 0px;
			max-width: 95%;
			margin: auto;
			margin-top: 50px;
		}

		text-align: center;
	}

	padding: 0px;
	min-height: 100vh;
}

@media (max-width: 768px) {
	.roi-box {

		.roi-box-title {
			font-size: 20px;
		}

		.roi-box-description {
			font-size: 13px;
		}

		padding: 10px 20px 45px 12px;
	}

	.ivu-cell {
		padding: 7px 16px !important;
	}

	.ivu-cell-footer {
		right: 16px !important;
	}

	.ivu-modal {

		.ivu-modal-content {
			border-radius: 0px;
		}

		width: auto!important;
		margin: 0px !important;
		top: 0px !important;
	}
}
</style>
