import Vue from "vue"
import {
	Button,
	ButtonGroup,
	Layout,
	Card,
	Content,
	Steps,
	Step,
	Icon,
	Slider,
	InputNumber,
	Modal,
	CellGroup,
	Cell,
	Collapse,
	Panel,
	Tag,
	Row,
	Col
} from "iview"
import lang from "iview/dist/locale/en-US"
import { locale } from "iview"

locale(lang)

// This is how you import shit for now:
// Vue.prototype.$Message = Message

Vue.component("ButtonGroup", ButtonGroup)
Vue.component("Button", Button)
Vue.component("Layout", Layout)
Vue.component("Card", Card)
Vue.component("Content", Content)
Vue.component("Steps", Steps)
Vue.component("Step", Step)
Vue.component("Icon", Icon)
Vue.component("Slider", Slider)
Vue.component("InputNumber", InputNumber)
Vue.component("Modal", Modal)
Vue.component("CellGroup", CellGroup)
Vue.component("Cell", Cell)
Vue.component("Collapse", Collapse)
Vue.component("Panel", Panel)
Vue.component("Tag", Tag)
Vue.component("Row", Row)
Vue.component("Col", Col)

import "iview/dist/styles/iview.css"
